/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import {
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  HStack,
  Icon,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import { Icons } from '@tonic/central-specialties-ui-themed';
import { useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { EntityCardColumnsView } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumnsView.tsx';
import { LoadingLogoAndSpinner } from '../../../components/general/LoadingLogoAndSpinner.tsx';
import { RepairRequestSummaryModal } from './RepairRequestSummaryModal/RepairRequestSummaryModal.tsx';
import { RepairRequestFormModal } from '../../../components/general/Form/specific/RepairRequestForm/RepairRequestFormModal.tsx';
import { RepairRequestCard } from '../../../components/general/cards/RepairRequestCard.tsx';
import { ArchivedRepairRequestsModal } from './ArchivedRepairRequestsModal/ArchivedRepairRequestsModal.tsx';
import {
  FilterButton,
  FiltersData,
} from '../../../components/general/filtering/FilterButton.tsx';
import { downloadObjectsAsCSV } from '../../../utils/downloadObjectsAsCSV.ts';
import { NotificationButton } from '../../../components/notification/NotificationButton.tsx';
import { useNavigate, useParams } from 'react-router-dom';

const ticketPriorityOrder = ['Critical', 'High', 'Medium', 'Low', 'Winter'];

const filterPaths: FiltersData<Schemas['RepairRequestResponse']> = {
  'Job Number': {
    path: ['job', 'number'],
    formatOptions: 'noNull',
  },
  Mechanic: {
    path: ['assignedTo', 'fullName'],
    formatOptions: 'noNull',
  },
  'Equipment Number': {
    path: ['equipment', 'number'],
    formatOptions: 'noNull',
  },
  Status: {
    path: ['status'],
  },
  Priority: { path: ['priority'] },
};

export const RepairsPage = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const [selectedRepairRequest, setSelectedRepairRequest] = useState<
    Schemas['RepairRequestResponse'] | null
  >(null);
  const [showRepairRequestFormModal, setShowRepairRequestFormModal] =
    useState(false);
  const [showArchivedTicketsModal, setShowArchivedTicketsModal] =
    useState(false);
  const [filteredRepairRequests, setFilteredRepairRequests] = useState<
    Schemas['RepairRequestResponse']
  >([]);

  const {
    data: repairRequestsResponse,
    isPending: repairRequestsPending,
    error: repairRequestsError,
    refetch: refetchRepairRequests,
  } = useQuery({
    queryKey: ['repairRequests'],
    queryFn: async () =>
      await DB.GET('/repair-requests').then(
        (res: { data: Schemas['RepairRequestResponse'][] }) => res.data,
      ),
  });

  const { Active: repairRequests, Archived: archivedRepairRequests } =
    useMemo(() => {
      if (!repairRequestsResponse) return { Archived: [], Active: [] };
      const data = repairRequestsResponse;
      data.sort((a, b) => {
        const aIndex = ticketPriorityOrder.indexOf(a.priority);
        const bIndex = ticketPriorityOrder.indexOf(b.priority);
        if (aIndex === bIndex) {
          return a.createdAt > b.createdAt ? -1 : 1;
        }
        return aIndex < bIndex ? -1 : 1;
      });
      // group by status 'Archived' and 'Active' (any status other than 'Archived')
      return data.reduce(
        (acc, v) => {
          // Repairs have 'Cancelled' status in place of 'Archived', though page language still indicates 'Archived'
          if (v.status === 'Cancelled') {
            acc.Archived.push(v);
          } else {
            acc.Active.push(v);
          }
          return acc;
        },
        { Archived: [], Active: [] },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repairRequestsResponse, refetchRepairRequests]);

  const removeProperties = (array: any[]) => {
    return array.map((item) => {
      // Create fallbacks for possibly null structures
      const { id, ...rest } = item;
      const job = item.job ?? {};
      const equipment = item.equipment ?? {};
      const createdBy = item.createdBy ?? {};
      const assignedTo = item.assignedTo ?? {};

      const {
        id: jobId,
        createdAt: jobCreatedAt,
        updatedAt: jobUpdatedAt,
        ...jobRest
      } = job;
      const {
        id: equipmentId,
        description: equipmentDescription,
        ...equipmentRest
      } = equipment;
      const {
        id: createdById,
        firstName: createdByFirstName,
        lastName: createdByLastName,
        phoneNumber: createdByPhoneNumber,
        startDate: createdByStartDate,
        isDisabled: createdByIsDisabled,
        fullName: createdByFullName,
        ...createdByRest
      } = createdBy;
      const {
        id: assignedToId,
        employeeNumber: assignedToEmployeeNumber,
        email: assignedToEmail,
        firstName: assignedToFirstName,
        lastName: assignedToLastName,
        phoneNumber: assignedToPhoneNumber,
        startDate: assignedToStartDate,
        isDisabled: assignedToIsDisabled,
        ...assignedToRest
      } = assignedTo;

      // Return the transformed object with the unnecessary properties removed
      return {
        ...rest,
        job: jobRest,
        equipment: equipmentRest,
        createdBy: createdByRest,
        assignedTo: assignedToRest,
      };
    });
  };

  const exportToJSON = useCallback(() => {
    const name = 'RepairRequests_' + new Date().toISOString();
    const trimmedRequests = removeProperties(repairRequests);

    downloadObjectsAsCSV(trimmedRequests, name);
  }, [repairRequests]);

  useEffect(() => {
    if (ticketId) {
      const notificationItem = repairRequestsResponse?.find(
        (rr) => rr.id === ticketId,
      );
      if (notificationItem) {
        setSelectedRepairRequest(notificationItem);
      }
    }
  }, [repairRequestsResponse, ticketId]);

  if (repairRequestsPending) {
    return (
      <Center w="$full" h="$full">
        <LoadingLogoAndSpinner />
      </Center>
    );
  }

  if (repairRequestsError) {
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving repair requests.
        </Text>
      </Center>
    );
  }

  return (
    <PageWrapper
      ToolbarContent={
        <HStack flex={1} justifyContent="space-between">
          <HStack space="xl" alignItems="center">
            <Button
              size="lg"
              onPress={() => setShowRepairRequestFormModal(true)}
            >
              <ButtonIcon as={Icons.Plus} />
              <ButtonText>Create Ticket</ButtonText>
            </Button>
            <Button size="lg" variant="outline" onPress={exportToJSON}>
              <ButtonIcon as={Icons.Download} />
              <ButtonText>Export</ButtonText>
            </Button>
          </HStack>

          <HStack space="xl" alignItems="center">
            <NotificationButton pageSource={'Repair'} />
            <Button
              size="lg"
              variant="ghost"
              onPress={() => setShowArchivedTicketsModal(true)}
            >
              <ButtonIcon as={Icons.Archive} />
              <ButtonText>Archived Tickets</ButtonText>
            </Button>

            <FilterButton
              data={repairRequests}
              setFilteredData={setFilteredRepairRequests}
              filtersData={filterPaths}
            />
          </HStack>
        </HStack>
      }
    >
      <HStack w="$full" h="$full" px="$5" pt="$4">
        <ScrollView
          horizontal
          flex={1}
          h="$full"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'space-between',
          }}
          space="lg"
          pr="$3"
        >
          <EntityCardColumnsView
            data={filteredRepairRequests.filter(
              (req) => req.priority !== 'Winter',
            )}
            groupByPath="status"
            columnGroupings={[
              { value: 'New', indicatorDotVariant: 'neutral' },
              {
                value: 'Assigned',
                label: 'Assigned/In Progress',
                indicatorDotVariant: 'neutral',
              },
              {
                label: 'Completed',
                indicatorDotVariant: 'neutral',
                data: filteredRepairRequests.filter(
                  (req) =>
                    req.status === 'Completed' || req.status === 'Closed',
                ),
              },
              {
                label: 'Winter Maintenance',
                indicatorDotVariant: 'neutral',
                data: filteredRepairRequests.filter(
                  (req) => req.priority === 'Winter',
                ),
              },
            ]}
            CardComponent={({
              data,
            }: {
              data: Schemas['RepairRequestResponse'];
            }) => (
              <RepairRequestCard
                repairRequest={data}
                onPress={(rr) => setSelectedRepairRequest(rr)}
              />
            )}
            columnContainerProps={{ minWidth: '$70' }}
          />
        </ScrollView>
      </HStack>

      {!selectedRepairRequest && showArchivedTicketsModal && (
        <ArchivedRepairRequestsModal
          isOpen={showArchivedTicketsModal}
          onClose={() => setShowArchivedTicketsModal(false)}
          onSelectTicket={(ticket) => {
            setShowRepairRequestFormModal(true);
            setSelectedRepairRequest(ticket);
          }}
          repairRequests={archivedRepairRequests}
        />
      )}

      {showRepairRequestFormModal && (
        <RepairRequestFormModal
          isOpen={showRepairRequestFormModal}
          onClose={() => {
            setShowArchivedTicketsModal(false);
            setShowRepairRequestFormModal(false);
            setSelectedRepairRequest(null);
          }}
          repairRequest={selectedRepairRequest}
          refetch={refetchRepairRequests}
        />
      )}

      {!!selectedRepairRequest && !showRepairRequestFormModal && (
        <RepairRequestSummaryModal
          repairRequest={selectedRepairRequest}
          isOpen={true}
          onClose={() => {
            navigate('/Repair', { replace: true });
            setSelectedRepairRequest(null);
          }}
          onPressEdit={() => setShowRepairRequestFormModal(true)}
          refetch={refetchRepairRequests}
        />
      )}
    </PageWrapper>
  );
};
