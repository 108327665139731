import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  DB,
  HStack,
  Icons,
  Modal,
  ModalBackdrop,
  ModalContent,
  ModalHeader,
  Text,
  useUser,
  View,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Heading, Icon, Pressable, ScrollView } from '@gluestack-ui/themed';
import { useQuery } from '@tanstack/react-query';
import { ChevronRight } from 'react-native-feather';

export interface NotificationDrawerProps
  extends React.ComponentProps<typeof ModalContent> {
  isOpen: boolean;
  onClose: () => void;
  defaultFilterType?: string;
}

const NotificationSourceKeys = [
  'truckingRequestId',
  'timeCardEntryId',
  'repairRequestId',
  'partsTicketId',
];

const iconMap = {
  Repair: Icons.Tool,
  'Time Card': Icons.Clock,
  Parts: Icons.Hexagon,
  Trucking: Icons.Truck,
  Critical: Icons.AlertTriangle,
};

const RoutePageMap = {
  truckingRequestId: 'Truck',
  timeCardEntryId: 'Time',
  repairRequestId: 'Repair',
  partsTicketId: 'Parts',
};
const NotificationTypeMap = {
  All: 'All',
  Time: 'TimeEntry',
  Parts: 'PartsRequest',
  Repair: 'RepairRequest',
  Truck: 'TruckingRequest',
};

interface NotificationProp {
  type: string;
  sender: string;
  jobNumber: string;
  isRead: boolean;
  sentAt: string | Date;
  truckingRequestId: string | null;
  timeCardEntryId: string | null;
  repairRequestId: string | null;
  partsTicketId: string | null;
}

interface NotificationCardDataProp {
  subject: string;
  subheader: string;
  isRead: boolean;
  icon: string;
  messageId: string;
  type: string;
}

interface NotificationsResponse {
  totalCount: number;
  unreadCount: number;
  notifications: NotificationProp[];
}

interface NotificationCardProp {
  iconName: IIconComponentType<any>;
  subject: string;
  subheader: string;
  readMessage: boolean;
  onPress: () => void;
}

export const NotificationDrawer = ({
  isOpen,
  onClose,
  defaultFilterType,
  ...modalContentProps
}: React.PropsWithChildren<NotificationDrawerProps>) => {
  const navigate = useNavigate();
  const user = useUser();
  const isRepairAdmin = user?.roles?.includes('RepairAdmin');
  const [, setNotifications] = useState<NotificationProp[]>([]);
  const [notificationsCardData, setNotificationsCardData] = useState<
    NotificationCardDataProp[]
  >([]);
  const [page] = useState(1);
  const [pageSize] = useState(100);
  const [selectedFilter, setSelectedFilter] = useState(
    defaultFilterType?.pageSource ?? 'All',
  );
  const { data: notificationsData, isPending: notificationsPending } = useQuery(
    {
      queryKey: ['notifications'],
      queryFn: async () =>
        DB.GET('/notifications', {
          params: {
            query: {
              page,
              pageSize,
              types: NotificationTypeMap[defaultFilterType?.pageSource],
            },
          },
        }).then((res: { data: NotificationsResponse[]; error: any }) => {
          if (res.error) {
            console.error(res.error);
            throw res.error;
          }
          return res.data?.notifications?.filter(
            (notification) => !notification.isRead,
          );
        }),
    },
  );

  const processNotifications = useCallback(
    (notificationsData) => {
      let messageId: string;
      let messageType: string;

      const processedData = notificationsData
        .filter(
          (notification) =>
            notification.type !== 'CriticalTicketCreation' ||
            (notification.type === 'CriticalTicketCreation' && isRepairAdmin),
        )
        .map((notification: NotificationProp) => {
          const { sentAt, type } = notification;

          const messageKey = NotificationSourceKeys.filter(
            (key) => notification[key] !== null,
          ).map((key) => {
            messageId = notification[key];
            messageType = key;
            let formattedKey = key
              .replace('RequestId', '')
              .replace('EntryId', '')
              .replace('TicketId', '');

            formattedKey = formattedKey
              .replace(/^[a-z]/, (char) => char.toUpperCase())
              .replace(/([a-z])([A-Z])/g, '$1 $2');
            return formattedKey;
          })[0];

          const date = new Date(sentAt);
          const formatter = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          });

          const isCriticalRepairTicket =
            messageKey === 'Repair' && type === 'CriticalTicketCreation';

          return {
            subject: isCriticalRepairTicket
              ? `New Critical ${messageKey} Ticket`
              : `${messageKey} Request for job #${notification.jobNumber}`,
            subheader: `${notification.sender} - ${formatter.format(date)}`,
            isRead: notification?.isRead,
            icon: isCriticalRepairTicket ? 'Critical' : messageKey,
            messageId: messageId,
            messageType: messageType,
            sentAt,
          };
        });

      return processedData;
    },
    [isRepairAdmin],
  );

  const handleNotificationNavigation = (
    messageDestination: string,
    ticketId: string,
  ) => {
    const destinationKey = RoutePageMap[messageDestination];
    navigate(`/${destinationKey}/${ticketId}`);
    onClose();
  };

  const handleNotificationFilter = useCallback(
    async (filterType: string) => {
      const typeInput = NotificationTypeMap[filterType];
      try {
        const response = await DB.GET('/notifications', {
          params: {
            query: {
              page,
              pageSize,
              types: typeInput !== 'All' ? typeInput : '',
            },
          },
        });

        if (response?.data) {
          const notificationData = response.data?.notifications;
          const processedData = processNotifications(notificationData);
          setNotifications(notificationData);
          setNotificationsCardData(processedData);
          setSelectedFilter(filterType);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [page, pageSize, processNotifications],
  );

  useEffect(() => {
    if (!notificationsPending) {
      const processedData = processNotifications(notificationsData);
      setNotifications(notificationsData);
      setNotificationsCardData(processedData);
    }
  }, [notificationsData, notificationsPending, processNotifications]);

  useEffect(() => {
    const setInitialCall = async () => {
      try {
        await handleNotificationFilter(selectedFilter);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    if (isOpen) {
      setInitialCall();
    }
  }, [isOpen, handleNotificationFilter, selectedFilter]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      useRNModal
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'flex-end',
      }}
    >
      <ModalBackdrop />
      <ModalContent
        borderTopLeftRadius="$md"
        borderBottomLeftRadius="$md"
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        h="$full"
        w="$100"
        {...modalContentProps}
      >
        <ModalHeader w="$full" alignItems="center">
          <Box w="$7" />
          <Heading>Notifications</Heading>
          <Pressable width="$7" onPress={onClose}>
            <Icon as={Icons.X} size="xl" />
          </Pressable>
        </ModalHeader>
        <VStack
          alignItems="center"
          w="$full"
          flex={1}
          justifyContent="space-between"
        >
          <VStack w="$full" alignItems="center" space="lg">
            <Text
              fontSize={16}
              color="$black"
              textAlign="center"
              w="$full"
              lineHeight={'$xl'}
              mt={'$1'}
              fontWeight="$bold"
              borderBottomWidth={4}
              borderBottomColor="$primary600"
            >
              Messages & Status Updates
            </Text>
            <HStack
              w="$full"
              alignItems="center"
              backgroundColor="$white"
              justifyContent="center"
              space="lg"
              borderBottomWidth={1}
              borderBottomColor="#e0e0e0"
              paddingTop={12}
              top={-16}
            >
              {['All', 'Repair', 'Parts', 'Time', 'Truck'].map((filter) => (
                <FilterPill
                  key={filter}
                  filterType={filter}
                  selected={selectedFilter === filter}
                  onPress={() => handleNotificationFilter(filter)}
                />
              ))}
            </HStack>
            {notificationsCardData.length === 0 && (
              <Text
                fontSize={14}
                color="$black"
                fontWeight="$bold"
                textAlign="center"
                w="$full"
                height="$32"
                margin={'auto'}
                justifyContent="center"
                alignContent="center"
              >
                No Notifications
              </Text>
            )}
            <ScrollView
              h="$full"
              w="$full"
              style={{ flex: 1 }}
              showsVerticalScrollIndicator={true}
            >
              <View style={{ height: '80vh' }}>
                {notificationsCardData.length > 0 &&
                  notificationsCardData.map((notification) => {
                    return (
                      <NotificationCard
                        iconName={iconMap[notification.icon]}
                        subject={notification?.subject}
                        subheader={notification?.subheader}
                        readMessage={notification?.isRead}
                        onPress={async () =>
                          handleNotificationNavigation(
                            notification?.messageType,
                            notification?.messageId,
                          )
                        }
                      />
                    );
                  })}
              </View>
            </ScrollView>
          </VStack>
          <HStack w="$full" mb="$16" />
        </VStack>
      </ModalContent>
    </Modal>
  );
};

const NotificationCard = ({
  iconName,
  subject,
  subheader,
  readMessage = true,
  onPress,
}: NotificationCardProp) => {
  const iconColor = readMessage ? '#A9A9A9' : '$primary700';
  const textWeight = readMessage ? '200' : 'bold';

  return (
    <TouchableOpacity onPress={onPress} style={styles.cardContainer}>
      <View style={styles.row}>
        <Icon as={iconName} color={iconColor} size="xl" />
        <View style={styles.textContainer}>
          <Text style={[styles.subject, { fontWeight: textWeight }]}>
            {subject}
          </Text>
          <Text style={[styles.subheader]}>{subheader}</Text>
        </View>
        <ChevronRight stroke={readMessage ? '#A9A9A9' : '#BE0202'} />
      </View>
    </TouchableOpacity>
  );
};

const FilterPill = ({
  filterType,
  onPress,
  selected,
}: {
  filterType: string;
  onPress: () => void;
  selected: boolean;
}) => {
  const pillColor = !selected ? '#FFF' : '#9D0101';
  const pillTextColor = !selected ? '#000' : '#FFF';

  return (
    <TouchableOpacity
      style={[styles.filterPill, { backgroundColor: pillColor }]}
      onPress={onPress}
    >
      <Text style={[styles.pillText, { color: pillTextColor }]}>
        {filterType}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'normal',
    justifyContent: 'flex-start',
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    maxHeight: 70,
    width: '100%',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 'auto',
  },
  textContainer: {
    flex: 1,
    marginLeft: 12,
  },
  subject: {
    fontSize: 16,
    color: '#000',
  },
  subheader: {
    fontSize: 12,
    color: '#666',
    fontWeight: '300',
  },
  filterPill: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 30,
    marginRight: 4,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5, // Adds shadow on Android
  },
  pillText: {
    fontSize: 14,
    fontWeight: '600',
  },
});
