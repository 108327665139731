import React, { useState } from 'react';
import { Button, ButtonIcon, ButtonText } from '@gluestack-ui/themed';
import { DB, Icons, View } from '@tonic/central-specialties-ui-themed';
import { NotificationDrawer } from './NotificationDrawer';
import { useQuery } from '@tanstack/react-query';

interface NotificationProp {
  type: string;
  sender: string;
  jobNumber: string;
  isRead: boolean;
  sentAt: string | Date;
  truckingRequestId: string | null;
  timeCardEntryId: string | null;
  repairRequestId: string | null;
  partsTicketId: string | null;
}
interface NotificationsResponse {
  totalCount: number;
  unreadCount: number;
  notifications: NotificationProp[];
}

export const NotificationButton = (pageSource) => {
  const [openNotification, setOpenNotification] = useState(false);
  const openNotificationsDrawer = () => {
    setOpenNotification(true);
  };

  const {
    data: notificationsDataUnreadCount,
    isPending: notificationsPending,
  } = useQuery({
    queryKey: ['notificationsCount'],
    queryFn: async () =>
      DB.GET('/notifications', {
        params: {
          query: { onlyUnread: true },
        },
      }).then((res: { data: NotificationsResponse[]; error: any }) => {
        if (res.error) {
          console.error(res.error);
          throw res.error;
        }
        return res.data?.unreadCount;
      }),
    refetchInterval: 15000, // Poll every 15 seconds
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    enabled: true,
  });

  return (
    <Button size="lg" variant="ghost" onPress={openNotificationsDrawer}>
      {!notificationsPending && notificationsDataUnreadCount > 0 && (
        <View
          position="absolute"
          left={16}
          top={10}
          bgColor={'$gray900'}
          borderRadius={10}
          w={12}
          h={12}
          justifyContent="center"
          alignItems="center"
        />
      )}
      <ButtonIcon as={Icons.Bell} />
      <ButtonText>Notifications</ButtonText>
      <NotificationDrawer
        isOpen={openNotification}
        onClose={() => setOpenNotification(false)}
        defaultFilterType={pageSource}
      />
    </Button>
  );
};
